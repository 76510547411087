import React from "react";
import { Button } from "antd";

interface IProps {
  onClick: Function;
  text: string;
  disabled?: boolean | undefined;
  style?: React.CSSProperties;
  htmlType?: "button" | "reset" | "submit" | undefined;
}

export const ExxonButton: React.FC<IProps> = (props: IProps) => {
  const { onClick, text } = props;
  const style: React.CSSProperties = {
    background: "#2f66f2",
    borderColor: "#2f66f2",
    fontFamily: "Emprint-regular",
  };

  return (
    <Button
      size="large"
      className="button_blue"
      style={props.style ? { ...style, ...props.style } : style}
      disabled={props.disabled}
      type="primary"
      htmlType={props.htmlType}
      onClick={() => onClick()}
    >
      {text}
    </Button>
  );
};
