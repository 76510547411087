import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { Row, Col, message } from "antd";

import { ConfirmedIcon, VehicleIcon } from "../../../../../assets/SvgIcons";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useWindowSize } from "../../../../Utilities";
import { getWorkshops } from "../../../../../stores/selectors";
import {
  filterAppointmentStatus,
  filterNotificationStatus,
  workshopSelected,
} from "shared";

import "./styles.css";
import { CkMessage } from "../../../../../CkUI";

interface NotificationProps {
  id: number | string;
  userId: number | string;
  notificationActionCode: string;
  isVisible: boolean;
  createdDate: string;
  modifiedDate: string;
  redirectUrl: string | null;
  redirectUrlWS: string | null;
  notificationText: string;
  workshopAppointmentId: number | null;
  endConsumerRequestId: number | null;
  workshopId: number | null;
  isNew: boolean;
  user: any | null;
}

interface INotif {
  history: any;
  notification: NotificationProps;
  redirect: Function | null;
  setANotificationAsNotNew: Function;
  closeRedirect: Function;
}

interface IProps {
  notification: {
    appointment: any;
    vehicleNickname?: string;
    vehicleBrandName?: string;
    vehicleModelName?: string;
    buttonTopRight: React.ReactNode;
    HideNotification: Function;
    setANotificationAsNotNew: Function;
    buttonRating?: React.ReactNode | null;
    isNotification?: boolean | undefined;
    pending?: boolean | undefined;
    animation?: number;
    isCalledFromNavBar?: boolean | undefined;
    redirectURL?: string;
    closeOnRedirect?: Function;
  };
}

const GetMonthByNumber = (index: number) => {
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  return months[index];
};

const GetDayByNumber = (index: number) => {
  const days = [
    "Domingo,",
    "Lunes,",
    "Martes,",
    "Miércoles,",
    "Jueves,",
    "Viernes,",
    "Sábado,",
  ];
  return days[index];
};

const conversationNotificacionCodes = [
  "CONSUMERREPLY",
  "CONTINUECONVERSATION",
  "CONSUMERDOUBT",
  "CONSUMERQUERY",
  "QUOTESOLICITATIONWORKSHOPREMINDER",
];

const Notification: React.FC<INotif> = ({
  history,
  notification,
  redirect,
  setANotificationAsNotNew,
  closeRedirect,
}) => {
  const { notificationText, redirectUrlWS } = notification;

  let updatedHtml = notificationText;

  const url: string = redirect();

  const toShowConsoleLog = async () => {
    if (notification.workshopId) {
      if (notification.notificationActionCode != "WORKSHOPREGISTER") {
        await closeRedirect(notification.workshopId);
      }
      setANotificationAsNotNew(notification.id);
      history.push(url);
    } else {
      if (notification.notificationActionCode === "WORKSHOPAPPROVEBYSU") {
        setANotificationAsNotNew(notification.id);
        const redirectUrl = url.split("/");
        history.push(redirectUrl[3]);
      } else {
        CkMessage({
          type: "error",
          text: "No es posible la navegación en esta notificación",
        });
      }
    }
  };

  const propertyName = `func_${notification.id}`;
  window[propertyName] = toShowConsoleLog;

  // `<a href="javascript:void(0)" onClick="window['${propertyName}']()">`
  // `<a href="${url}" onClick="window['${propertyName}']()">`

  if (redirect) {
    updatedHtml = notificationText.replace(
      /<a>/g,
      `<a href="javascript:void(0)" onClick="window['${propertyName}']()">`
    );
  }

  return (
    <p
      className="notif-info"
      dangerouslySetInnerHTML={{ __html: updatedHtml }}
    />
  );
};

const MemoizedAppointmentBasicInfo: React.FC<IProps> = ({
  appointment,
  buttonTopRight,
  HideNotification,
  setANotificationAsNotNew,
  buttonRating,
  pending,
  isNotification,
  vehicleNickname,
  vehicleBrandName,
  vehicleModelName,
  animation,
  isCalledFromNavBar,
  redirectURL,
  closeOnRedirect,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const windowSize = useWindowSize();

  const workshops: any[] = useSelector(getWorkshops);

  const [notificationClass, setNotificationClass] = React.useState<string>(
    "filter-no-animation"
  );

  const redirect = (url: string) => {
    const redirectUrl = url.split("/");
    history.push(redirectUrl[3]);
  };

  const setIndex = (selected: number) => {
    dispatch(workshopSelected(selected));
    closeOnRedirect();
  };

  const getIndex = (workshopId: number) => {
    const index = workshops.findIndex(
      (workshop) => workshop.id === workshopId.toString()
    );
    setIndex(index);
  };

  const generateNotificationActionUrl = () => {
    if (
      conversationNotificacionCodes.indexOf(
        appointment.notificationActionCode
      ) !== -1
    ) {
      if (!!appointment.endConsumerRequestId)
        return `/chats?requestId=${appointment.endConsumerRequestId}`;
      if (!!appointment.workshopAppointmentId)
        return `/chats?appointmentId=${appointment.workshopAppointmentId}`;
      if (
        appointment.notificationActionCode ===
        "QUOTESOLICITATIONWORKSHOPREMINDER"
      )
        return `/chats?requestId=${appointment.endConsumerRequestId}`;
      return `/chats`;
    } else {
      const url: string = appointment.redirectUrlWS;
      const regex = /(^|\/)cita(\/|$)/;
      const rateRegex = /(^|\/)calificaciones(\/|$)/;

      if (regex.test(url)) {
        return `/cita/${
          !!appointment.workshopAppointmentId
            ? appointment.workshopAppointmentId
            : ""
        }`;
      } else if (rateRegex.test(url)) {
        return `/calificaciones/${
          !!appointment.workshopId ? appointment.workshopId : ""
        }`;
      } else if (appointment.notificationActionCode == "WORKSHOPREGISTER") {
        return `/mis-talleres?indexId=${appointment.workshopAppointmentId}`;
      } else {
        return url;
      }
    }
  };

  const closeRedirect = () => {
    HideNotification && HideNotification();
    closeOnRedirect && closeOnRedirect();
  };

  /**
   * If component is called from navbar, notification drawer
   */
  if (isCalledFromNavBar)
    return (
      <div
        className={[
          "notif-container",
          appointment.isNew ? "is-new" : "",
          appointment.appointmentId === animation ? notificationClass : "",
        ].join(" ")}
      >
        <div className="notif-col">
          <div
            className={[
              "notif-row-container",
              appointment.isNew ? "row-new" : "",
            ].join(" ")}
          >
            {appointment.isNew && (
              <div className="new-circle-container">
                <span className="new-circle" />
              </div>
            )}

            <div className="notif-date-container">
              <p className="notif-date">
                {GetDayByNumber(
                  moment(appointment.createdDate, "YYYY-MM-DDTHH:mmZ").day()
                )}
                &nbsp;
                {moment(appointment.createdDate, "YYYY-MM-DDTHH:mmZ").date() <
                10
                  ? "0" +
                    moment(appointment.createdDate, "YYYY-MM-DDTHH:mmZ").date()
                  : moment(appointment.createdDate, "YYYY-MM-DDTHH:mmZ").date()}
                &nbsp;{"de "}
                {GetMonthByNumber(
                  moment(appointment.createdDate, "YYYY-MM-DDTHH:mmZ").month()
                )}
                {","}
                &nbsp;
                {moment(appointment.createdDate, "YYYY-MM-DDTHH:mmZ").year()}
              </p>
              {pending ? (
                <div className="notif-clear">{buttonTopRight}</div>
              ) : null}
            </div>
          </div>

          <div>
            <Notification
              history={history}
              notification={appointment}
              redirect={redirectURL ? generateNotificationActionUrl : null}
              setANotificationAsNotNew={setANotificationAsNotNew}
              closeRedirect={getIndex}
            />
          </div>
        </div>
      </div>
    );

  /**
   * If component is called from outside navbar
   */
  return (
    <div
      className={[
        appointment.appointmentId === animation ? "filter-animation" : "",
        isNotification
          ? isNotification
            ? `--background-${
                filterNotificationStatus(
                  "notificationActionCode",
                  appointment.notificationActionCode
                ).statusCode
              }`
            : `--background-${appointment.statusCode}`
          : `--background-${appointment.statusCode}`,
      ].join(" ")}
      style={{
        // backgroundColor: GetColorFromStatus(appointment.statusCode),
        borderRadius: isNotification ? "10px" : "6px 6px 0 0",
        padding: "20px",
      }}
    >
      <Row>
        <Col flex="auto">
          <Row style={{ margin: 0 }} align="middle" justify="start">
            {isNotification ? (
              <Col>
                <ConfirmedIcon />
              </Col>
            ) : null}
            <Col>
              <p
                style={{
                  color: "white",
                  margin: "0",
                  lineHeight: "24px",
                  fontFamily: "var(--font-family)",
                  fontWeight: 400,
                  fontSize: "16px",
                }}
              >
                {
                  filterAppointmentStatus("statusCode", appointment.statusCode)
                    .status
                }
              </p>
            </Col>
          </Row>
        </Col>
        {pending ? (
          <Col flex="40px" style={{ textAlign: "end" }}>
            {buttonTopRight}
          </Col>
        ) : null}
      </Row>
      <Row gutter={20} style={{ alignItems: "end" }}>
        <Col span={7}>
          <p
            style={{
              fontSize: isNotification
                ? "55px"
                : windowSize.width <= 385
                ? "79px"
                : "89px",
              margin: "0",
              textAlign: "end",
              color: "white",
              lineHeight: windowSize.width <= 385 ? "79px" : "89px",
            }}
          >
            {moment(appointment.startDateTime, "YYYY-MM-DDTHH:mmZ").date() < 10
              ? "0" +
                moment(appointment.startDateTime, "YYYY-MM-DDTHH:mmZ").date()
              : moment(appointment.startDateTime, "YYYY-MM-DDTHH:mmZ").date()}
          </p>
        </Col>
        <Col>
          <Row>
            <p
              style={{
                fontSize: isNotification ? "12.8px" : "16px",
                margin: "0",
                color: "white",
                lineHeight: "16px",
                marginTop: "25px",
              }}
            >
              {GetMonthByNumber(
                moment(appointment.startDateTime, "YYYY-MM-DDTHH:mmZ").month()
              )}{" "}
              &nbsp;{" "}
              {moment(appointment.startDateTime, "YYYY-MM-DDTHH:mmZ").year()}
            </p>
          </Row>
          <Row>
            <p
              className="bold"
              style={{
                fontSize: isNotification ? "20px" : "31px",
                margin: "0",
                color: "white",
              }}
            >
              {GetDayByNumber(
                moment(appointment.startDateTime, "YYYY-MM-DDTHH:mmZ").day()
              )}{" "}
              {moment(appointment.startDateTime, "YYYY-MM-DDTHH:mmZ").hour() <
              10
                ? "0" +
                  moment(appointment.startDateTime, "YYYY-MM-DDTHH:mmZ").hour()
                : moment(appointment.startDateTime, "YYYY-MM-DDTHH:mmZ").hour()}
              :
              {moment(appointment.startDateTime, "YYYY-MM-DDTHH:mmZ").minute() <
              10
                ? "0" +
                  moment(
                    appointment.startDateTime,
                    "YYYY-MM-DDTHH:mmZ"
                  ).minute()
                : moment(
                    appointment.startDateTime,
                    "YYYY-MM-DDTHH:mmZ"
                  ).minute()}
            </p>
          </Row>
        </Col>
      </Row>
      <Row gutter={10} style={{ marginTop: "5px" }}>
        <Col span={3}>
          {isNotification ? (
            <VehicleIcon
              style={{
                color: "white",
                width: "25px",
                position: "absolute",
                bottom: 0,
              }}
            />
          ) : (
            <VehicleIcon style={{ color: "white", width: "32px" }} />
          )}
        </Col>
        <Col span={21}>
          <h6 className="white">
            {vehicleNickname === null || vehicleNickname === undefined
              ? "Mi vehículo"
              : vehicleNickname.trim() === ""
              ? "Mi vehículo"
              : vehicleNickname}
          </h6>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={21} offset={3}>
          <p
            style={{
              fontSize: "13px",
              lineHeight: "13px",
              fontFamily: "var(--font-family)",
              fontWeight: 400,
              margin: "0",
              color: "white",
            }}
          >
            {vehicleBrandName} &nbsp;
            {vehicleModelName}
          </p>
        </Col>
      </Row>
      {buttonRating ? (
        <Row
          gutter={10}
          justify="center"
          align="middle"
          style={{ marginTop: "20px" }}
        >
          <Col span={24}>{buttonRating}</Col>
        </Row>
      ) : null}
    </div>
  );
};

export const AppointmentBasicInfo = React.memo(
  MemoizedAppointmentBasicInfo,
  (prev, next) => {
    return !(
      prev.vehicleNickname !== next.vehicleNickname ||
      prev.vehicleModelName !== next.vehicleModelName ||
      prev.vehicleBrandName !== next.vehicleBrandName ||
      prev.redirectURL !== next.redirectURL ||
      prev.pending !== next.pending ||
      prev.isNotification !== next.isNotification ||
      prev.isCalledFromNavBar !== next.isCalledFromNavBar ||
      prev.children !== next.children ||
      prev.buttonRating !== next.buttonRating ||
      prev.appointment !== next.appointment ||
      prev.animation !== next.animation
    );
  }
);
