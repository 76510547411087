import React, {useState} from "react";
import PopoverPrevious from "../../../../assets/SvgIcons/PopoverPrevious";
import PopoverNext from "../../../../assets/SvgIcons/PopoverNext";
import PopoverDelete from "../../../../assets/SvgIcons/PopoverDelete";
import {Button, Image, Popover} from "antd";
import {MoreOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";
import {getSasToken} from "../../../../stores";

const ImgComponent: React.FC<{
  photoUrl: string;
  imgIndex: number;
  reorganizeImages: Function;
  imageList: any;
  deleteImage: Function;
}> = (
  {
    photoUrl,
    imgIndex,
    reorganizeImages,
    imageList,
    deleteImage,
  }) => {
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const sasToken: string = useSelector(getSasToken);

  const handleVisibility = (visibility: boolean) => setShowPopover(visibility);

  const PopoverContent = (
    <div className="pop-over-container">
      {imgIndex > 1 && (
        <div
          className="label"
          style={{ color: "#2F66F2" }}
          onClick={() => {
            reorganizeImages(imgIndex, imgIndex - 1);
            setShowPopover(false);
          }}
        >
          <PopoverPrevious />
          Mover antes
        </div>
      )}
      {imgIndex > 0 && imgIndex !== imageList.length - 1 && (
        <div
          className="label"
          style={{ color: "#2F66F2" }}
          onClick={() => {
            reorganizeImages(imgIndex, imgIndex + 1);
            setShowPopover(false);
          }}
        >
          <PopoverNext />
          Mover después
        </div>
      )}
      <div
        className="label"
        style={{ color: "#F00", gap: 13 }}
        onClick={() => {
          deleteImage(imgIndex);
          setShowPopover(false);
        }}
      >
        <PopoverDelete style={{ marginLeft: 3 }} />
        Borrar
      </div>
    </div>
  );

  return (
    <div className="ws-profile-img-component">
      <Image
        className="img-container ws-profile-image"
        preview={false}
        src={`${photoUrl}?${sasToken}`}
      />
      <Popover
        zIndex={2}
        overlayInnerStyle={{ borderRadius: "10px" }}
        placement="topRight"
        content={PopoverContent}
        trigger="click"
        open={showPopover}
        onOpenChange={handleVisibility}
        getPopupContainer={(triggerNode) => triggerNode || document.body}
      >
        <Button
          onClick={() => setShowPopover((old) => !old)}
          className="icon-btn more-btn"
          icon={<MoreOutlined />}
        />
      </Popover>
    </div>
  );
};

export default ImgComponent;