import React from "react";
import { useHistory } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { Menu, Avatar, Popover } from "antd";
import { User } from "shared";
import { CkIcon } from "../../../../CkUI";

import "./styles.css";
import { gtmSetId } from "../../../Utilities";

interface IProps {
  hideIcons?: boolean;
  logout: Function;
  user: User;
  sasToken: string;
  isMobile?: boolean;
}

const MemoizedUserMenu: React.FC<IProps> = ({
  hideIcons,
  logout,
  user,
  sasToken,
  isMobile,
}) => {
  const history = useHistory();

  const subMenu = (
    <Menu className="user-submenu-content">
      <Menu.Item
        key="Profile"
        onClick={
          !hideIcons ? () => history.push("/configuracion/data") : () => {}
        } 
        hidden={hideIcons}
      >
        <div 
          className="submenu-item" 
          onClick={(ev) => {
            gtmSetId(ev.currentTarget);
          }}
          id="HDRLOGIN005"
        >
          Ver perfil
        </div>
      </Menu.Item>
      <Menu.Item
        key="Section 5"
        onClick={() => {
          logout();
        }}
      >
        <div 
          className="submenu-item" 
          onClick={(ev) => {
            gtmSetId(ev.currentTarget);
          }}
          id="HDRLOGIN008"
        >
          Cerrar sesión
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="user-menu-container">
      <Popover
        key="Section 3"
        content={subMenu}
        showArrow={false}
        trigger="click"
        placement="bottomRight"
        align={{
          offset: [
            0, /* calculateOffset(`${user?.name} ${user?.lastName}`.length), */
            3.5,
          ],
        }}
        className="popover-menu"
        overlayClassName="popover-menu-overlay"
        getPopupContainer={(e) => e}
      >
        <div className="nav-popover-menu">
          <Avatar
            size={!isMobile ? 56 : 38}
            src={
              sasToken &&
              user?.photoUrl !== "" &&
              `${user?.photoUrl}?${sasToken}`
            }
            icon={<UserOutlined />}
            className="user-avatar"
          />
          {!isMobile && (
            <>
              <p>{`${user?.name} ${user?.lastName}`}</p>
              <CkIcon name="caret-filled-down" className="dropdown-icon" />
            </>
          )}
        </div>
      </Popover>
    </div>
  );
};

export const UserMenu = React.memo(MemoizedUserMenu);
