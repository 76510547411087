import React, { memo, useEffect, useState, useRef, FC } from "react";
import { Layout, Row, Col, Button, Modal, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SendOutlined } from "@ant-design/icons";

import { WorkshopSwitch } from "../ChangeWorkshop";

import { PromoCodesAPI } from "../../../../app/Services/axios";
import { CurrentUser } from "../../../../context/UserContext";

import {
  getWorkshops,
  getWorkshopSelected,
  getCurrentSection,
  getRatings,
  getSasToken,
  toogleExpandedMenu,
} from "../../../../stores";
import { Workshop } from "shared/src/data-models/Workshop";

import {
  useIsMobileScreen,
  useWindowSize,
} from "../../../Utilities/WindowSize";

import {
  SmallTimeGreenIcon,
  ToDoIcon,
  CloseIcon,
} from "../../../../assets/SvgIcons";

import { UserMenu } from "../UserMenu";
import NotificationBell from "./NotificationBell/index";

import "./styles.css";
import { getImageBaseUrl } from "../../../../app/Utilities";
import { CkButton, CkModal, CkRate, CkMessage, gtmSetId } from "../../../../CkUI";
const Logo = getImageBaseUrl("logos/logo.svg");
const { Header } = Layout;
interface IProps {
  logout: Function;
  hideIcons: boolean;
  viewQuizModal?: Function;
  dsrStatusCode: string | null;
  showInProgressModal?: Function;
  showCongratulationsModal?: Function;
  showRetakeModal?: Function;
  type?: "OWN" | "ADM" | "MEC" | null;
}
const MainHeader: FC<IProps> = ({
  logout,
  hideIcons,
  viewQuizModal = null,
  dsrStatusCode,
  showInProgressModal = null,
  showCongratulationsModal = null,
  showRetakeModal = null,
  type,
}) => {
  const dispatch = useDispatch();
  const headerRef = useRef<HTMLElement>();
  const user = React.useContext(CurrentUser);

  const history = useHistory();
  const windowSize = useWindowSize();
  const isMobile = useIsMobileScreen(992);

  const sasToken: string = useSelector(getSasToken);
  const workshops: Workshop[] = useSelector(getWorkshops);
  const workshopSelected: number = useSelector(getWorkshopSelected);
  const currentSection = useSelector(getCurrentSection);
  const ratings = useSelector(getRatings);

  const [showRating, setShowRating] = useState<boolean>(false);
  const [showPromoModal, setShowPromoModal] = useState<boolean>(false);
  const [showPromoSended, setShowPromoSended] = useState<boolean>(false);
  const [invitedEmail, setInvitedEmail] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    calculateNavigationHelperWidth();
  }, []);

  useEffect(() => {
    calculateNavigationHelperWidth();
  }, [
    windowSize,
    currentSection,
    ratings
  ]);

  /**
   * This function calculate and set max-width for navigation helper element
   * Takes the desktop-view width, and header-options width, the substract of this values minus 15px gap
   * will be the maximum width for navigation helper on desktop
   * note: if windows width is <= 992px, navigation helper maximum width will be equals to the windows width
   */
  const calculateNavigationHelperWidth = () => {
    if (
      !!windowSize === false ||
      !!windowSize?.width === false ||
      headerRef.current === undefined
    )
      return;
    if (windowSize.width <= 992) {
      const navigationHelper = headerRef.current?.querySelector(
        ".mobile-view .navigation-helper"
      ) as HTMLElement | null;
      if (!!navigationHelper)
        navigationHelper.style.maxWidth = `${
          headerRef.current.getBoundingClientRect().width
        }px`;
    } else {
      const desktopView = headerRef.current?.querySelector(
        ".desktop-view"
      ) as HTMLElement | null;
      const navigationHelper = headerRef.current?.querySelector(
        ".desktop-view .navigation-helper"
      ) as HTMLElement | null;
      const headerOptions = headerRef.current?.querySelector(
        ".desktop-view .header-options"
      ) as HTMLElement | null;
      if (desktopView && navigationHelper && headerOptions) {
        navigationHelper.style.maxWidth = `${
          desktopView.getBoundingClientRect().width -
          headerOptions.getBoundingClientRect().width -
          15
        }px`;
      }
    }
  };

  const HandleSendInvitation = ({ email }: any) => {
    setLoading(true);
    setInvitedEmail(email);
    PromoCodesAPI.getInvitationCode({
      userReferrerId: parseInt(user?.id!),
      userInvitedEmail: email,
    })
      .then((result) => {
        if (result) {
          if (result.data !== "") {
            PromoCodesAPI.inviteUser({
              senderUserId: parseInt(user?.id!),
              email,
            })
              .then((result) => {
                if (result.status === 200) {
                  setShowPromoSended(true);
                }
              })
              .finally(() => {
                setLoading(false);
              });
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 409) {
          CkMessage({
            type: "error",
            text: "Ya existe una invitación a ese correo electrónico.",
          });
        } else {
          CkMessage({
            type: "error",
            text: "Hubo un problema al enviar la invitación, intenta nuevamente",
          });
        }
        setLoading(false);
      });
  };

  const GetColorByDsrStatus = () => {
    if (dsrStatusCode === "2") {
      return "#CE3003";
    } else if (dsrStatusCode === "3") {
      return "#299F5B";
    } else if (dsrStatusCode === "4" || dsrStatusCode === "5") {
      return "#FF8C00";
    }
  };

  const getModal = (code: string | null) => {
    if (code === "2") {
      return !!showInProgressModal && showInProgressModal(true);
    } else if (code === "3") {
      return !!showCongratulationsModal && showCongratulationsModal(true);
    } else if (code === "4" || code === "5") {
      return !!showRetakeModal && showRetakeModal(true);
    }
  };

  const getDescription = (code: string | null) => {
    if (code === "2") {
      return "Evaluando Respuestas";
    } else if (code === "3") {
      return "Aceptado";
    } else if (code === "4" || code === "5") {
      return "El equipo CarKer te contactará";
    }
  };

  const NavigationHelperContent = () => {
    if (currentSection === "ratings")
      return (
        <div className="rate-display-container">
          {ratings?.workshopName && (
            <p className="workshop-name">
              Calificación de {ratings?.workshopName}
            </p>
          )}
          {ratings?.total && (
            <div className="rate-display">
              <CkRate disabled allowHalf value={parseFloat(ratings?.total)} />
              <div className="action-container">
                <p className="rate-total">{ratings?.total} de 5</p>
                {ratings?.averages.length > 0 && (
                  <CkButton
                    block={false}
                    onClick={() => setShowRating(true)}
                    variant="link"
                  >
                    Ver detalle
                  </CkButton>
                )}
              </div>
            </div>
          )}
        </div>
      );

    if (currentSection !== "")
      return (
        <div className="current-section">
          <p>{currentSection}</p>
        </div>
      );

    return (
      <WorkshopSwitch
        alterText={
          <p
            className="medium blue"
            style={{
              marginLeft: windowSize.width >= 992 ? 30 : "0",
              textAlign: windowSize.width >= 992 ? "left" : "center",
            }}
          >
            {workshops.length > 0 ? workshops[workshopSelected]?.name! : ""}
            {workshops.length > 0
              ? workshops[workshopSelected]?.sucursalName
                ? " - " + workshops[workshopSelected]?.sucursalName
                : ""
              : ""}
          </p>
        }
      />
    );
  };

  const DesktopView = () => (
    <div className={`desktop-view dsrStatusCode-${dsrStatusCode}`}>
      <div className="navigation-helper">
        <NavigationHelperContent />
      </div>

      <div className="header-options">
        {/*1 ___________________DSR Button - Notification Bell___________________ */}
        <div
          style={{
            display: "flex",
            position: "relative",
            top: "4px",
            marginRight: "20px",
          }}
        >
          {!isMobile && <NotificationBell />}
        </div>

        {/*___________________Visibilidad taller___________________*/}
        {type !== "MEC" && dsrStatusCode === "1" && (
          <Button
            size="large"
            onClick={(ev) => {
              gtmSetId(ev.currentTarget);
              !!viewQuizModal && viewQuizModal(true)
            }}
            style={{
              backgroundColor: "#BD0112",
              color: "white",
            }}
            className="quiz-button"
            id="HDRLOGIN002"
          >
            <ToDoIcon className="img-icon-doublespecial" />
            ¡Haz que tu taller sea visible!
          </Button>
        )}

        {/*___________________Taller Status ___________________*/}
        {dsrStatusCode !== "3" &&
          dsrStatusCode !== "1" &&
          dsrStatusCode !== null && (
            <Button
              onClick={(ev) => {
                dsrStatusCode === "2" && gtmSetId(ev.currentTarget)
                getModal(dsrStatusCode);
              }}
              style={{
                backgroundColor: "white",
                color: dsrStatusCode === "2" ? "#CE3017" : "#6D6E71",
                borderColor: GetColorByDsrStatus(),
              }}
              className="quiz-button"
              {...(dsrStatusCode === "2" ? {
                id: "HDRLOGIN106"
              } : {})}
            >
              <SmallTimeGreenIcon
                style={{
                  stroke: GetColorByDsrStatus(),
                }}
                className="img-icon-double"
              />
              {getDescription(dsrStatusCode)}
            </Button>
          )}

        {/*___________________User Menu___________________*/}
        <UserMenu
          hideIcons={hideIcons}
          logout={logout}
          sasToken={sasToken}
          user={user!}
          isMobile={windowSize.width < 1200}
        />
      </div>
    </div>
  );

  const MobileView = () => (
    <div className="mobile-view mobile">
      <div className="header-options">
        <div className="left-side">
          <CkButton
            icon={"menu-hamburger"}
            onClick={() => dispatch(toogleExpandedMenu(true))}
            shape="circle"
            variant="text"
          />
        </div>

        <div className="center-side">
          <img
            src={Logo}
            alt="CarKer logo"
            onClick={() => {
              history.push("/");
            }}
            width="90px"
            style={{ cursor: "pointer" }}
          />
        </div>

        <div className="right-side">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingRight: 10,
              // paddingRight: 18,
              // paddingTop: 18,
            }}
          >
            {isMobile && <NotificationBell />}
          </div>
          {/*___________________Visibilidad taller___________________*/}
          {type !== "MEC" &&
            dsrStatusCode === "1" &&
            windowSize.width >= 560 && (
              <Button
                size="large"
                onClick={(ev) => {
                  gtmSetId(ev.currentTarget);
                  !!viewQuizModal && viewQuizModal(true)
                }}
                style={{
                  backgroundColor: "#BD0112",
                  color: "white",
                }}
                className="quiz-button"
                id="HDRLOGIN002"
              >
                <ToDoIcon className="img-icon-doublespecial" />
              </Button>
            )}
          <UserMenu
            hideIcons={hideIcons}
            logout={logout}
            sasToken={sasToken}
            user={user!}
            isMobile={true}
          />
        </div>
      </div>

      <div className="navigation-helper">
        <NavigationHelperContent />
      </div>
    </div>
  );

  return (
    <Header className="main-header" ref={headerRef}>
      {DesktopView()}
      {MobileView()}
      {ratings && (
        <CkModal
          title={"Detalle de calificación"}
          className="show-rating-modal"
          open={showRating}
          onCancel={() => setShowRating(false)}
          zIndex={5000}
        >
          {ratings?.total && (
            <div className="total-container">
              <p className="label">Calificación total:</p>
              <div>
                <CkRate disabled allowHalf value={parseFloat(ratings?.total)} />
                <p className="value">{ratings?.total} de 5</p>
              </div>
            </div>
          )}
          {ratings?.averages && (
            <div className="averages-container">
              {ratings.averages &&
                ratings.averages.map((avg) => (
                  <div className="average">
                    <p className="label">{avg.feedbackCategoryName}:</p>
                    <div>
                      <CkRate
                        disabled
                        allowHalf
                        value={parseFloat(avg.rating)}
                      />
                      <p className="value">{avg.rating} de 5</p>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </CkModal>
      )}
      <Modal
        open={showPromoModal}
        title={null}
        footer={null}
        onCancel={setShowPromoModal.bind(null, false)}
        bodyStyle={{
          backgroundColor: "#FFF",
          padding: "15px max(2%, 15px)",
          borderRadius: "10px",
        }}
        className="promo-modal"
        width={windowSize.width <= 992 ? "100%" : "810px"}
        closeIcon={<CloseIcon style={{ color: "black" }} />}
        centered
        destroyOnClose
        forceRender
        zIndex={3001}
      >
        <Row gutter={[10, 15]}>
          <Col span={24}>
            <span
              style={{
                fontFamily: "Emprint-regular",
                fontSize: windowSize.width < 576 ? 20 : "31px",
                color: "#2f66f2",
              }}
            >
              ¡Recomienda a tus clientes CarKer y recibe puntos acumulables para
              realizar compras en Amazon™!
            </span>
          </Col>
          <Col span={24}>
            <Form
              onFinish={HandleSendInvitation}
              className="promo-form"
              name="promoForm"
              initialValues={{ remember: true }}
              onFinishFailed={(failed) => {
                // console.log(failed);
              }}
            >
              <Row style={{ margin: 0 }} gutter={[6, 0]}>
                <Col lg={20} xs={24}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Ingresa el correo electrónico",
                      },
                      {
                        type: "email",
                        message: "Debes ingresar un correo electrónico",
                      },
                    ]}
                    name="email"
                    style={{
                      color: "white",
                      backgroundColor: "rgb(255, 255, 255)",
                      height: "62px",
                      borderRadius: "0px",
                      width: "100%",
                      textAlign: "center",
                      verticalAlign: "center",
                      transform: "skew(-20deg)",
                      border: "1px solid rgb(209, 211, 212)",
                      marginBottom: 10,
                    }}
                  >
                    <Input
                      placeholder="Correo electrónico"
                      style={{
                        borderRadius: "0px",
                        height: "60px",
                        border: "none",
                        transform: "skew(20deg)",
                        width: "95%",
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col lg={4} xs={24}>
                  <Form.Item noStyle>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{
                        color: "white",
                        backgroundColor: "#2f66f2",
                        height: "62px",
                        borderRadius: "0px",
                        width: "100%",
                        transform: "skew(-20deg)",
                      }}
                      loading={loading}
                    >
                      <span style={{ transform: "skew(20deg)" }}>Enviar</span>
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <p
              style={{
                color: "#6D6E71",
                fontSize: "14px",
                margin: "10px 0 0",
                fontFamily: "emprint-regular",
              }}
            >
              Registra un correo electrónico a la vez
            </p>
          </Col>
          <Col span={24}>
            <Button
              style={{
                color: "#0C479D",
                backgroundColor: "#FFF",
                height: "62px",
                borderRadius: "8px",
                border: "1px solid #D1D3D4",
                width: "100%",
              }}
              onClick={() => {
                setShowPromoModal(false);
                document.body.style.removeProperty("overflow");
                history.push("/recompensas-carker");
              }}
            >
              ¿Cómo funciona?
            </Button>
          </Col>
        </Row>
      </Modal>
      <Modal
        centered
        style={{ color: "#454545", textAlign: "center" }}
        bodyStyle={{
          backgroundColor: "#FFF",
          padding: "7%",
          borderRadius: "10px",
        }}
        className="promo-modal"
        open={showPromoSended}
        width={windowSize.width <= 992 ? "100%" : "578px"}
        footer={null}
        onCancel={setShowPromoSended.bind(null, false)}
        closeIcon={<CloseIcon style={{ color: "black" }} />}
        destroyOnClose
        forceRender
      >
        <Row gutter={[10, 48]}>
          <Col span={24}>
            <SendOutlined
              style={{
                fontSize: "4rem",
                color: "lightgray",
                marginTop: "1rem",
              }}
            />
          </Col>
          <Col span={24}>
            <span
              style={{
                color: "#0C479D",
                marginTop: "1rem",
                marginBottom: "3rem",
                fontFamily: "Emprint-regular",
                fontSize: "25px",
              }}
            >
              ¡Invitación enviada!
            </span>
          </Col>
          <Col span={24}>
            <span style={{ fontFamily: "Emprint-regular", fontSize: "20px" }}>
              Se ha enviado una invitación al correo electrónico
              <br />
              <strong style={{ marginTop: "1rem" }}>{invitedEmail}</strong>
              <br />
            </span>
          </Col>
        </Row>
      </Modal>
    </Header>
  );
};

export default memo(MainHeader);
