import React, { useEffect, useState, FC } from "react";
import {
  Badge,
  message,
  Drawer,
  Row,
  Col,
  Divider,
  Modal,
  Button,
  Spin,
} from "antd";
import { CheckOutlined } from "@ant-design/icons";

import { UserAPI } from "../../../../../app/Services/axios";
import {
  useWindowSize,
  useQuery,
  gtmAddLayer,
  useIsMobileScreen,
  gtmSetId,
} from "../../../../Utilities";

import { AppointmentBasicInfo } from "../AppointmentBasicInfo";
import { XIcon } from "../../../../../assets/SvgIcons";
import { CurrentUser } from "../../../../../context/UserContext";
import { CkIcon, CkMessage } from "../../../../../CkUI";
import "./styles.css";
import { useDispatch } from "react-redux";
import { setNotificationNumber } from "../../../../../stores";


let intervalId: any = null;

interface IProps {}

const NotificationBell: FC<IProps> = () => {
  const user = React.useContext(CurrentUser);
  const [appointments, setAppointments] = useState<any[]>([]);
  const [deletedNotif, setDeletedNotif] = useState<any[]>([]);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const windowSize = useWindowSize();
  const dispatch = useDispatch();
  const isMobile = useIsMobileScreen(992);

  const getNotifications = async () => {
    let sortedNotifications: any = [];
    UserAPI.getUserNotifications(user?.id)
      .then((result) => {
        sortedNotifications = result.data.sort(
          (a: { createdDate: number }, b: { createdDate: number }) =>
            a.createdDate < b.createdDate ? 1 : -1
        );
        hideNotif(deletedNotif, sortedNotifications);

        // setAppointments((prevAppointments) => {
        //   if (sortedNotifications.length > prevAppointments.length) {
        //     return sortedNotifications;
        //   } else {
        //     return prevAppointments;
        //   }
        // });
        setIsLoading(false);
      })
      .catch((err) => {});
  };

  const HideNotification = (index: number, notificationID: string) => {
    let appointmentsTemp = [...appointments];
    let deletedList = [...deletedNotif];
    deletedList.push({
      inx: index,
      notificationID: notificationID,
    });

    setDeletedNotif([...deletedList]);
    if (appointmentsTemp.length === 0) {
      setShowNotification(false);
    }
    UserAPI.setUserNotificationAsRead(parseInt(notificationID)).then(
      (result) => {}
    );
  };

  const hideNotif = (deletedData: any[], appointmentsData: any[]) => {
    let newNotifs: any[] = [];
    let deletedList = [...deletedData];
    let appointmentsTemp = [...appointmentsData];

    if (deletedList.length > 0) {
      deletedList.map((del, index: number) => {
        newNotifs = appointmentsTemp.filter(
          (res, i: number) => res.id !== del.notificationID
        );
      });
    } else {
      newNotifs = appointmentsData;
    }

    setAppointments([...newNotifs]);
  };

  const HandleOpenNotification = (ev) => {
    gtmAddLayer({ event: "click_alerts_bar" });
    gtmSetId(ev.currentTarget)
    if (appointments.length === 0) {
      CkMessage({type: "success", text: "No tienes notificaciones"});
    } else {
      setShowNotification(true);
    }
  };

  const setAsNotNewNotifications = () => {
    if (appointments && appointments.length > 0) {
      setIsLoading(true);
      let idsArray: Array<any> = [];
      appointments.map((res) => (res.isNew ? idsArray.push(res.id) : null));
      if (idsArray && idsArray.length > 0) {
        UserAPI.setAsNotNewNotifications(idsArray)
          .then(() => {
            CkMessage({type: "success", text: "Bandeja actualizada"});
            // setAppointments([]);
            setIsLoading(false);
            setShowNotification(false);
            getNotifications();
          })
          .catch(() => {
            CkMessage({
              type: "error",
              text: "Ups, ocurrió un error al actualizar la bandeja"
            });
          });
      } else {
        setIsLoading(false);
        setShowNotification(false);
        CkMessage({type: "info", text: "No hay notificaciones nuevas"});
      }
    } else {
      CkMessage({type: "info", text: "No hay notificaciones nuevas"});
    }
  };

  const setANotificationAsNotNew = (id: number) => {
    setIsLoading(true);
    UserAPI.setAsNotNewNotifications([id])
      .then(() => {
        CkMessage({type: "success", text: "Bandeja actualizada"});
        // setAppointments([]);
        setIsLoading(false);
        setShowNotification(false);
        getNotifications();
      })
      .catch(() => {
        CkMessage({type: "error", text: "Ups, ocurrió un error al actualizar la bandeja"});
      });
  };

  const clearNotifications = (toNew?: boolean) => {
    if (appointments && appointments.length > 0) {
      setIsLoading(true);
      let idsArray: Array<any> = [];
      appointments.map((res) => idsArray.push(res.id));
      if (idsArray && idsArray.length > 0) {
        UserAPI.clearNotifications(idsArray, toNew)
          .then(() => {
            CkMessage({type: "success", text: "Bandeja limpia"});
            setAppointments([]);
            setIsLoading(false);
            setShowNotification(false);
          })
          .catch(() => {
            CkMessage({
              type: "error",
              text: "Ups, ocurrió un error al limpiar la bandeja"
            });
          });
      } else {
        setIsLoading(false);
      }
    } else {
      CkMessage({type: "info", text: "No hay notificaciones por limpiar"});
    }
  };

  const checkUrlParams = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const ck_show_notifications = urlParams.get("ck_show_notifications");
    if (ck_show_notifications === "1") {
      setShowNotification(true);
    }
  };

  useEffect(() => {
    if (user) {
      checkUrlParams();
      getNotifications();
      intervalId = setInterval(() => {
        getNotifications();
      }, 10000);
    }
    return () => {
      intervalId && clearInterval(intervalId);
    };
  }, [user]);

  useEffect(() => {
    hideNotif(deletedNotif, appointments);
  }, [deletedNotif]);

  useEffect(() => {
    const notificationNumber = appointments.filter((res) => res.isNew).length
    dispatch(setNotificationNumber(notificationNumber))
  }, [appointments]);

  const content = () => {
    return isLoading ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "20px",
        }}
      >
        <Spin size="large" />
      </div>
    ) : (
      <Row
        gutter={[20, 20]}
        style={{
          margin: 0,
        }}
      >
        {appointments.map((appointment, index) => (
          <Col
            key={`${index}appointments`}
            flex={windowSize.width >= 992 ? "393px" : "auto"}
            style={{ padding: "0px" }}
          >
            <AppointmentBasicInfo
              pending
              isNotification
              appointment={appointment}
              buttonTopRight={
                <XIcon
                  style={{
                    color: "black",
                    width: "25px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    HideNotification(index, appointment.id);
                  }}
                />
              }
              setANotificationAsNotNew={setANotificationAsNotNew}
              HideNotification={() => HideNotification(index, appointment.id)}
              closeOnRedirect={() => setShowNotification(false)}
              redirectURL={appointment.redirectUrlWS}
              isCalledFromNavBar={true}
            />
            {/* {index == appointments.length - 1 ? null : ( */}
            <Divider
              style={{
                borderWidth: "1px",
                borderColor: "#CCCCCC",
                margin: "10px 0px",
              }}
              dashed
            />
            {/* )} */}
          </Col>
        ))}
      </Row>
    );
  };

  return (
    <>
      <Badge
        count={appointments.filter((res) => res.isNew).length}
        overflowCount={99}
        /* offset={[-3, 4]}
        style={{ backgroundColor: "var(--mariner-color)" }} */
        className="notification-bell"
      >
        <Button 
          aria-label="Ver notificaciones"
          onClick={HandleOpenNotification}
          icon={<CkIcon name="bell" />}
          id="HDRLOGIN001"
        />
      </Badge>

      {/* NOTIFICATION DRAWER */}
      {!isMobile && (
        <Drawer
          className="notification-mobile"
          // title="Notificaciones"
          open={isMobile ? false : showNotification}
          footer={null}
          closeIcon={null}
          placement="right"
          bodyStyle={{ padding: windowSize.width >= 992 ? 10 : 24 }}
          onClose={() => {
            setShowNotification(false);
          }}
          width={400}
          maskClosable
          closable
          headerStyle={{
            padding: 0,
          }}
          zIndex={5000}
        >
          <div className="notif-header">
            <XIcon
              style={{
                color: "black",
                width: "30px",
                cursor: "pointer",
              }}
              onClick={setShowNotification.bind(null, false)}
            />
            <p className="notif-text-header">Notificaciones</p>
            <div className="notif-options-header">
              <Button
                disabled={isLoading}
                icon={<CheckOutlined />}
                onClick={() => setAsNotNewNotifications()}
              >
                Marcar todas como leídas
              </Button>
            </div>
          </div>
          <div className="scroll-modal">{content()}</div>
          <div className="options-modal">
            <Button
              disabled={isLoading}
              loading={isLoading}
              onClick={() => clearNotifications()}
            >
              Limpiar
            </Button>
          </div>
        </Drawer>
      )}

      {/* NOTIFICATION MODAL */}
      {isMobile && (
        <Modal
          className="notification-responsive-modal"
          // title="Notificaciones"
          open={isMobile ? showNotification : false}
          footer={null}
          bodyStyle={{
            marginTop: "-30px",
            padding: "0px",
            height: "698px",
          }}
          onCancel={() => {
            setShowNotification(false);
          }}
          onOk={() => {}}
          maskClosable
          closable
          width="330px"
          zIndex={5000}
        >
          <div className="notif-header">
            <XIcon
              style={{
                color: "black",
                width: "30px",
                cursor: "pointer",
              }}
              onClick={setShowNotification.bind(null, false)}
            />
            <p className="notif-text-header">Notificaciones</p>
            <div className="notif-options-header">
              <Button
                disabled={isLoading}
                icon={<CheckOutlined />}
                onClick={() => setAsNotNewNotifications()}
              >
                Marcar todas como leídas
              </Button>
            </div>
          </div>
          <div className="scroll-modal">{content()}</div>
          <div className="options-modal">
            <Button
              disabled={isLoading}
              loading={isLoading}
              onClick={() => clearNotifications()}
            >
              Limpiar
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default NotificationBell;
