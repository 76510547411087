import React, { FC, useEffect, useState, useContext } from "react";
import { Select } from "antd";
import { CurrentUser } from "../../../../context/UserContext";
import {
  getWorkshops,
  getWorkshopSelected,
} from "../../../../stores/selectors";
import { useLocation } from "react-router-dom";
import { Workshop } from "shared/src/data-models/Workshop";
import { useSelector, useDispatch } from "react-redux";
import { useWindowSize } from "../../../Utilities";
import { workshopSelected } from "shared";
import { CkIcon } from "../../../../CkUI";

import "./styles.css";

const { Option } = Select;

interface IActions {
  alterText?: any;
}

export const WorkshopSwitch: FC<IActions> = ({ alterText = <></> }) => {
  const user = useContext(CurrentUser);
  const location = useLocation();
  const { pathname } = location;
  const windowSize = useWindowSize();
  const dispatch = useDispatch();
  const workshops: Workshop[] = useSelector(getWorkshops);
  const varWorkshopSelected: number = useSelector(getWorkshopSelected);
  const [isMec, setIsMec] = useState<boolean>(false);
  const [isEnabled, setIsEnabled] = useState<boolean>(true);
  const [selectedWorkshop, setSelectedWorkshop] = useState<any>({
    label: "",
    value: "",
  });

  useEffect(() => {
    setIsEnabled(workshops[varWorkshopSelected].isEnabled);
  }, [varWorkshopSelected]);

  useEffect(() => {
    const prefix =
      pathname.includes("citas") && windowSize.width > 992
        ? "Administrar agenda de "
        : "";

    const isDisabledText = isEnabled ? "" : " (Inhabilitado)";

    if (workshops[varWorkshopSelected]) {
      setSelectedWorkshop({
        label: `${prefix}${workshops[varWorkshopSelected].name}${isDisabledText}`,
        value: varWorkshopSelected,
      });
    }
  }, [windowSize, pathname]);

  const setIndex = (selected: number) => {
    dispatch(workshopSelected(selected));
  };

  useEffect(() => {
    if (user && workshops[varWorkshopSelected]) {
      let myRole = user?.userRoles.map((res) =>
        res.workshopId == workshops[varWorkshopSelected].id
          ? res.roleCode
          : "undefined"
      );
      if (myRole[0] != "MEC") {
        setIsMec(false);
      }
    }
  }, [varWorkshopSelected]);

  if (isMec) return alterText;

  return (
    <div className="workshop-switch">
      <Select
        className="--custom workshop-select"
        defaultValue={varWorkshopSelected}
        onChange={setIndex}
        placeholder="Selecciona un taller"
        value={selectedWorkshop}
        popupClassName="workshop-switch-popover"
        suffixIcon={<CkIcon name="caret-down" />}
      >
        {workshops
          .filter((res) => res.email.length > 0)
          .map((res, i) => (
            <Option value={i} key={i}>
              {res.name}
            </Option>
          ))}
      </Select>
    </div>
  );
};
